<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 10:36:46
 * @LastEditTime: 2023-06-30 19:17
 * @Descripttion: 【作业考试-题库-试卷管理】试卷添加、编辑
-->
<style lang="scss" scoped>
    .paper-modify {
        @include innerPage;
        @include pageHead(-42px);

        .page-inner {
            width: 100%;
            height: calc(100% - 162px);
            box-sizing: border-box;
            padding: 38px 60px 0 0;
        }

        .modify-form {
            width: 100%;
            height: calc(100% - 68px);
            box-sizing: border-box;
            padding: 0 20px 0 50px;

            .form-inner {
                width: 100%;
                height: 100%;
                overflow: hidden;
                overflow-y: auto;
                box-sizing: border-box;
                padding-right: 28px;

                .page-form {
                    box-sizing: border-box;
                    padding-left: 8px;
                    padding-right: 16.6%;

                    .total-score {
                        display: inline-block;
                        min-width: 130px;
                        height: 46px;
                        padding: 0 20px;
                        border-radius: 10px;
                        background: #f1f2f7;
                        color: #191919;
                        line-height: 46px;
                    }
                }

                .create-paper {
                    border-top: 1px solid #e0e0e3;
                    margin-top: 20px;
                    padding-top: 20px;
                }

                .type-choose {
                    @include flexBox;

                    .group-item {
                        width: 278px !important;
                        margin-right: 10px;
                        margin-bottom: 0;
                    }
                }

                .paper-question {
                    width: 100%;
                    box-sizing: border-box;
                    padding-left: 82px;
                    padding-right: 16.6%;
                    margin-top: 44px;

                    .question {
                        &-wrapper {
                            width: 100%;
                        }

                        &-block {
                            box-sizing: border-box;
                            padding-left: 48px;
                            position: relative;
                            height: 58px;
                            overflow: hidden;
                            padding-bottom: 30px;

                            &::before {
                                content: '';
                                width: 1px;
                                height: calc(100% - 40px);
                                background-image: linear-gradient(to bottom, #baafe4 80%, rgba(255, 255, 255, 0) 0%);
                                background-position: left;
                                background-size: 1px 5px;
                                background-repeat: repeat-y;
                                position: absolute;
                                left: 15px;
                                top: 38px;
                                @include defaultAni;
                            }

                            &:last-child:not(.show):before {
                                max-height: 0;
                            }

                            .contract {
                                width: 30px;
                                height: 30px;
                                background: #e6e4f3;
                                border-radius: 8px;
                                border: 1px solid #6340c8;
                                position: absolute;
                                top: 3px;
                                left: 0;
                                cursor: pointer;

                                .h,
                                .v {
                                    display: inline-block;
                                    background: #6340c8;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }

                                .h {
                                    width: 12px;
                                    height: 2px;

                                }

                                .v {
                                    width: 2px;
                                    height: 12px;
                                }

                                &:hover {
                                    background: #6340c8;
                                    border: 1px solid #6340c8;

                                    .h,
                                    .v {
                                        background: #fff;
                                    }
                                }
                            }

                            &.show {
                                height: auto;

                                .contract .v {
                                    transform: translate(-50%, -50%) rotate(90deg);
                                }

                            }
                        }

                        &-type {
                            @include flexBox;
                            margin-bottom: 26px;

                            h5 {
                                margin-right: 20px;
                                color: #4c4c4c;
                            }
                        }

                        &-list {
                            box-sizing: border-box;
                            padding-left: 38px;
                            font-size: 14px;
                        }

                        &-title,
                        &-title--combination {
                            font-size: 16px;
                            color: #404040;
                            line-height: 28px;
                            margin: 18px 0;
                            position: relative;
                            @include flexBox;
                            align-items: baseline;

                            .el-image {
                                width: 60px;
                                height: 38px;
                                border-radius: 6px;
                                flex-shrink: 0;
                                margin-top: -5px;
                                margin: 0px 10px;
                                flex-shrink: 0;
                            }

                            .el-button {
                                flex-shrink: 0;
                            }
                        }

                        &-title {
                            &::before {
                                content: '';
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background: #d0cceb;
                                position: absolute;
                                top: 9px;
                                left: -66px;
                                z-index: 1;
                            }

                            &::after {
                                content: '';
                                width: 55px;
                                height: 1px;
                                background-image: linear-gradient(to right, #baafe4 80%, rgba(255, 255, 255, 0) 0%);
                                /* 35%设置虚线点x轴上的长度 */
                                background-position: bottom;
                                /* top配置上边框位置的虚线 */
                                background-size: 5px 1px;
                                /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
                                background-repeat: repeat-x;
                                position: absolute;
                                top: 12.5px;
                                left: -65px;
                                z-index: 0;
                            }
                        }

                        &-options,
                        &-answer {
                            box-sizing: border-box;
                            padding-left: 32px;
                            padding-right: 32px;
                            line-height: 26px;
                            color: #666;

                            .option {
                                @include flexBox;
                                align-items: baseline;
                                margin: 10px 0 0;

                                p {
                                    display: inline-flex;
                                    position: relative;
                                    align-items: baseline;
                                }

                                &:first-child {
                                    margin-top: 0;
                                }

                                &.is_answer p {
                                    &::after {
                                        content: '\e63a';
                                        font-family: 'iconfont';
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                        background: #2ac293;
                                        text-align: center;
                                        line-height: 20px;
                                        color: #fff;
                                        position: absolute;
                                        right: -30px;
                                        top: 3px;
                                        font-size: 12px;
                                    }
                                }

                                .el-image {
                                    width: 54px;
                                    height: 32px;
                                    border-radius: 6px;
                                    margin-left: 10px;
                                    flex-shrink: 0;
                                    // margin-top: -3px;
                                }
                            }
                        }

                        &-answer {
                            display: inline-block;
                            position: relative;
                            text-align: justify;

                            // span {
                            //     text-decoration: underline;
                            //     color: #2ac293;
                            // }

                            &::after {
                                content: '\e63a';
                                font-family: 'iconfont';
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                background: #2ac293;
                                text-align: center;
                                line-height: 20px;
                                color: #fff;
                                position: absolute;
                                right: 0;
                                top: 3px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .button-group {
            height: 84px;
            box-sizing: border-box;
            padding-top: 30px;
        }
    }

    .question-choose {
        height: 540px;
        box-sizing: border-box;
        padding-bottom: 38px;

        &.voluntarily {
            height: 400px;
        }

        .type-tip {
            color: #3f3f3f;
            height: 60px;
            box-sizing: border-box;
            border-bottom: 1px solid #e8e8e8;
            margin-top: 12px;
            @include flexBox;
        }

        .choose {
            &-type {
                height: 40px;
                margin-top: 30px;
                box-sizing: border-box;
                padding: 0 30px;
                @include flexBox;
            }

            &-manual {
                height: calc(100% - 142px);
                padding: 22px 0 0 22px;
                box-sizing: border-box;

                &--search {
                    width: 446px;
                    @include flexBox;

                    .el-button {
                        flex-shrink: 0;
                        margin-left: 10px;
                    }
                }

                &--question {
                    height: calc(100% - 74px);
                    overflow: auto;
                    margin-top: 28px;
                    box-sizing: border-box;
                    padding-top: 10px;

                    .question,
                    .subtopic {
                        &-list {
                            width: 100%;
                            box-sizing: border-box;
                            padding-right: 8px;
                            padding-top: 6px;
                        }

                        &-item {
                            width: 100%;
                            margin-bottom: 28px;
                            padding-bottom: 28px;
                            font-size: 14px;
                            border-bottom: 1px dashed #dfdfdf;
                            @include flexBox;
                            align-items: baseline;

                            &:last-child {
                                border-bottom: none;
                                padding-bottom: 0;
                                margin-bottom: 0;
                            }

                            &.combination {
                                .combination--title {
                                    font-size: 16px;
                                    color: #404040;
                                    line-height: 24px;
                                    margin: 18px 0;
                                }
                            }
                        }

                        &--no {
                            height: 26px;
                            line-height: 24px;
                            margin-right: 4px;
                            flex-shrink: 0;
                            @include flexBox;
                        }

                        &--num {
                            color: #1f1f1f;
                            font-size: 16px;
                        }

                        &--type {
                            min-width: 50px;
                            height: 24px;
                            background: #6340c8;
                            border-radius: 12px 0px 12px 12px;
                            text-align: center;
                            color: #fff;
                            margin-right: 8px;
                            font-size: 12px;
                            box-sizing: border-box;
                            padding: 0 4px;
                        }

                        &--data {
                            flex-grow: 1;

                            h5 {
                                line-height: 24px;
                                color: #1f1f1f;
                                @include flexBox;
                                align-items: baseline;
                            }
                        }

                        &--title {
                            position: relative;
                            box-sizing: border-box;
                            padding-right: 94px;
                            @include flexBox;
                            align-items: flex-start;
                            p{
                                align-items: flex-start;
                                display: flex;  
                            }
                            .el-image {
                                width: 60px;
                                height: 38px;
                                border-radius: 6px;
                                flex-shrink: 0;
                                margin-left: 8px;
                            }

                            .custom-input {
                                color: #272727;
                                margin-right: 20px;
                                flex-shrink: 0;
                                margin: -8px 10px 0;

                                span {
                                    color: #6340c8;
                                    margin-left: 8px;
                                }

                                ::v-deep .el-input {
                                    width: 58px;

                                    &__inner {
                                        height: 40px;
                                        border-color: #6340c8;
                                        padding: 0 4px;
                                        text-align: center;
                                        color: #6340c8;

                                        &:read-only {
                                            background: #f6f7fa;
                                            border-color: transparent;
                                        }
                                    }
                                }

                                @include flexBox;
                            }

                            .img-box {
                                height: 38px;
                                margin-top: -7px;
                            }

                            .el-checkbox {
                                padding-right: 0;
                                position: absolute;
                                right: 0;
                                top: -6px;
                            }

                            .title-text {
                                box-sizing: border-box;
                                padding-top: 8px;
                            }
                        }

                        &--options {
                            margin-top: 20px;

                            .option {
                                line-height: 24px;
                                color: #666666;
                                @include flexBox;
                                align-items: baseline;
                                margin-top: 10px;

                                .el-image {
                                    width: 54px;
                                    height: 32px;
                                    border-radius: 6px;
                                    margin-left: 10px;
                                    flex-shrink: 0;
                                    margin-top: -4px;
                                }
                            }
                        }

                        &--answer {
                            font-size: 16px;
                            line-height: 24px;
                            color: #2ac293;
                            margin-top: 20px;
                        }
                    }

                    .subtopic {
                        &-item {
                            margin-top: 10px;
                        }

                        &--no {
                            height: 40px;
                        }

                        &--type {
                            margin-left: 4px;
                        }

                        &--title {
                            p {
                                line-height: 24px;
                                padding: 8px 0;
                            }

                            .custom-input {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }

        &-voluntarily {
            margin-top: 18px;

            .el-form {
                box-sizing: border-box;
                padding: 0 30px;

                ::v-deep {
                    .el-form-item__label {
                        padding-left: 20px;
                        line-height: 36px;
                    }
                }
            }
        }
    }

    .paper-foot {
        width: 100%;
        flex-wrap: wrap;
        @include flexBox;

        .statistics {
            margin-right: auto;
            color: #313131;
            line-height: 36px;
            text-align: left;

            .pf_bold {
                font-size: 18px;

                &.score {
                    font-size: 20px;
                }
            }
        }

        &.wrap {
            height: 120px;
            box-sizing: border-box;
            padding: 14px 0;

            .statistics {
                width: 100%;
            }
        }
    }
    
.el-form {
  box-sizing: border-box;
  padding: 0 20px 0 34px;

  ::v-deep {
    .el-upload {
      &--text {
        width: 150px;
        height: 100px;

        .el-upload-dragger .iconfont {
          margin-top: 24px;
          margin-bottom: 0;
        }
      }

      .tips {
        margin-top: -14px;
      }
    }
  }
}

.file-list {
  .file-item {
    height: 60px;
    @include flexBox(space-between, flex-end);

    .iconfont-color {
      font-size: 52px;
      flex-shrink: 0;
      line-height: 60px;
      flex-shrink: 0;
    }

    .el-button {
      flex-shrink: 0;
      margin-bottom: 6px;
    }

    .name {
      color: #212122;
      flex-grow: 1;
      max-width: 328px;
      margin: 0 20px 10px;
    }
  }
}
</style>

<template>
    <section class="paper-modify">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb/>
                <el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
            </div>
        </div>
        <div class="page-inner">
            <div class="modify-form">
                <div class="form-inner">
                    <!-- 普通表单 -->
                    <el-form class="page-form" ref="paperForm" label-width="74px" :model="paperForm"
                             :rules="paperRules">
                        <el-form-item label="选择年级" prop="tepap_grade">
                            <el-select v-model="paperForm.tepap_grade" placeholder="请选择" clearable filterable>
                                <el-option v-for="item in gradeList" :key="item.grade_id" :label="item.grade_name"
                                           :value="item.grade_id"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择学期">
                            <el-radio-group v-model="paperForm.tepap_semester">
                                <el-radio :label="1">上学期</el-radio>
                                <el-radio :label="2">下学期</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="科目类型" prop="sysub_id">
                            <el-select v-model="paperForm.sysub_id" placeholder="请选择" clearable filterable>
                                <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                                           :value="item.sysub_id"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="试卷名称" prop="tepap_title">
                            <el-input v-model.trim="paperForm.tepap_title" maxlength="100" placeholder="请输入"/>
                        </el-form-item>
                        <el-form-item label="考试规则" class="textarea" prop="tepap_rule">
                            <el-input v-model.trim="paperForm.tepap_rule" type="textarea" maxlength="200"
                                      placeholder="请输入"/>
                        </el-form-item>
                        <el-form-item label="是否共享" v-if="userInfo.enter == 1">
                            <el-radio-group v-model="paperForm.tepap_isshare_status">
                                <el-radio :label="10">不共享</el-radio>
                                <el-radio :label="20">共享到学校</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="组题类型">
              <el-radio-group v-model="paperForm.tepap_make_type">
                <el-radio :label="10">自由组题</el-radio>
                <el-radio :label="20">pdf试卷</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="试卷附件"
              v-if="paperForm.tepap_make_type == 20"
              prop="tepap_att_paper_url"
            >
              <el-upload
                class="upload-table"
                :action="$upload.fileAction"
                :headers="$upload.header"
                drag 
                :show-file-list="attachmePaperList.length==0" 
                :file-list="attachmePaperList" 
                accept=".pdf"
                :before-upload="
                  (file) => {
                    return $beforFileUpload(file, ['pdf']);
                  }
                "
                :on-success="uploadPaperSuccess"
              >
                <p class="iconfont">&#xe603;</p>
                <p class="tips">拖拽或<span>点击上传</span></p>
              </el-upload>
              <ul class="file-list">
                <li
                  class="file-item"
                  v-for="(item, index) in attachmePaperList"
                  :key="index"
                >
                  <span class="iconfont-color" v-html="fileType(item.type)" />
                  <p class="name line-text--1st">{{ item.name }}</p>
                  <el-button
                    class="iconfont mini"
                    type="custom_primary"
                    circle
                    @click="removeCoursewareFile(1)"
                    >&#xe620;</el-button
                  >
                </li>
              </ul>
            </el-form-item>
            <el-form-item
              label="试卷解析"
              v-if="paperForm.tepap_make_type == 20"
              prop="tepap_att_analysis_url"
            >
              <el-upload
                class="upload-table"
                :action="$upload.fileAction"
                :headers="$upload.header"
                drag
                :show-file-list="attachmeanalysisList.length==0" 
                :file-list="attachmeanalysisList" 
                accept=".pdf"
                :before-upload="
                  (file) => {
                    return $beforFileUpload(file, ['pdf']);
                  }
                "
                :on-success="uploadSuccess"
              >
                <p class="iconfont">&#xe603;</p>
                <p class="tips">拖拽或<span>点击上传</span></p>
              </el-upload>
              <ul class="file-list">
                <li
                  class="file-item"
                  v-for="(item, index) in attachmeanalysisList"
                  :key="index"
                >
                  <span class="iconfont-color" v-html="fileType(item.type)" />
                  <p class="name line-text--1st">{{ item.name }}</p>
                  <el-button
                    class="iconfont mini"
                    type="custom_primary"
                    circle
                    @click="removeCoursewareFile(2)"
                    >&#xe620;</el-button
                  >
                </li>
              </ul>
            </el-form-item>
                    </el-form>
                    <!-- 题组创建 -->
                    <div class="create-paper" v-if="paperForm.tepap_make_type == 10">
                        <el-form class="page-form" ref="questionGroup" :model="questionGroup" :rules="questionRules"
                                 label-width="74px">
                            <el-form-item label="试卷总分">
                                <p class="total-score">{{ paperScoring || 0 }}分</p>
                            </el-form-item>
                            <el-form-item label="组卷操作" class="group">
                                <div class="type-choose">
                                    <el-form-item class="group-item" prop="tepap_top_title">
                                        <el-input v-model.trim="questionGroup.tepap_top_title" maxlength="100"
                                                  placeholder="请输入" clearable/>
                                    </el-form-item>
                                    <el-form-item class="group-item" prop="syque_typ_id">
                                        <el-select v-model="questionGroup.syque_typ_id" placeholder="请选择" clearable
                                                   filterable>
                                            <el-option v-for="item in questionType" :key="item.syque_typ_id"
                                                       :label="item.syque_typ_name" :value="item.syque_typ_id"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-button :disabled="!(paperForm.tepap_grade && paperForm.sysub_id)"
                                               type="custom_primary" size="medium" @click="addQuesGroup">添加题型+
                                    </el-button>
                                </div>
                            </el-form-item>
                        </el-form>
                        <!-- 组卷试题【已添加】 -->
                        <div class="paper-question">
                            <ul class="question-wrapper" v-if="!$isEmpty(paperForm.option_arr)">
                                <li class="question-block" :class="{ show: qg.show }"
                                    v-for="(qg, qg_index) in paperForm.option_arr" :key="qg_index">
                                    <p class="contract" @click="qg.show = !qg.show; $forceUpdate();">
                                        <i class="h"></i>
                                        <i class="v"></i>
                                    </p>
                                    <div class="question-type">
                                        <h5 class="bold">
                                            {{ qg_index + 1 | changeChinesNum }}、{{ qg.tepap_top_title }}
                                            （共{{ qg.option_item && qg.option_item.length || 0 }}题，总计{{
                                            qgScoring(qg.option_item) ||
                                            0 }}分）
                                        </h5>
                                        <el-button type="custom_success" size="small"
                                                   @click="showDialog(qg.syque_typ_id, qg_index)">
                                            选题
                                        </el-button>
                                        <el-button class="iconfont mini" type="custom_primary" circle
                                                   @click="delQg(qg_index)">
                                            &#xe620;
                                        </el-button>
                                    </div>
                                    <!-- 题目列表 -->
                                    <ul class="question-list" v-if="!$isEmpty(qg.option_item)">
                                        <li class="question-item" v-for="(que, que_index) in qg.option_item"
                                            :key="que.teque_id">
                                            <div class="question-title">
                                                <p>
                                                    {{ que_index + 1 > 9 && que_index + 1 || `0${que_index + 1}` }}.
                                                    
                                                    <el-image v-if="que.teque_title_image" fit="cover"
                                                          :src="formatfile(que.teque_title_image)"
                                                          :preview-src-list="[formatfile(que.teque_title_image)]"/>
                                                    <span class="richinline" v-html="que.teque_title"></span>  
                                                    （{{ que.teque_score }}分）
                                                </p>
                                                <el-button class="iconfont mini" type="custom_primary" circle
                                                           @click="delQues(qg_index, que_index)">
                                                    &#xe620;
                                                </el-button>
                                            </div>
                                            <!-- 组合题 -->
                                            <template v-if="que.syque_typ_id == 14">
                                                <ol class="combination-children">
                                                    <li class="combination-question"
                                                        v-for="(sque, sque_index) in que.children_question"
                                                        :key="sque_index">
                                                        <p class="question-title">
                                                            （{{ sque_index + 1 }}）
                                                            <el-image fit="scale-down" v-if="sque.teque_title_image"
                                                                      :src="formatfile(sque.teque_title_image)"
                                                                      :preview-src-list="[formatfile(sque.teque_title_image)]"/>
                                                            <span class="richinline" v-html="sque.teque_title"></span>
                                                            （ {{
                                                            sque.teque_score || 0
                                                            }}分 ）
                                                        </p>
                                                        <dl class="question-options" v-if="isChoice(sque.syque_typ_id)">
                                                            <dt class="option"
                                                                :class="{ is_answer: opt.teque_ite_isanswer == 10 }"
                                                                v-for="(opt, opt_index) in sque.question_item"
                                                                :key="opt_index">
                                                                <p>
                                                                    <span class="flex">
                                                                        {{ opt.teque_ite_code }}. <span class="richinline" v-html="opt.teque_ite_title"></span> 
                                                                    </span>
                                                                    <el-image fit="scale-down" v-if="opt.teque_ite_image"
                                                                              :src="formatfile(opt.teque_ite_image)"
                                                                              :preview-src-list="[formatfile(opt.teque_ite_image)]"/>
                                                                </p>
                                                            </dt>
                                                        </dl>
                                                        <p class="question-answer"
                                                           v-if="!isChoice(sque.syque_typ_id) && !$isEmpty(sque.sure_answer)">
                                                           <span class="richinline" v-html="sque.sure_answer.join(',')"></span>
                                                        </p>
                                                    </li>
                                                </ol>
                                            </template>
                                            <!-- 非组合题 -->
                                            <template v-if="que.syque_typ_id != 14">
                                                <div v-for="(sque, sque_index) in que.children_question"
                                                     :key="sque_index">
                                                    <ol class="question-options" v-if="isChoice(que.syque_typ_id)">
                                                        <li class="option"
                                                            v-for="(opt, opt_index) in sque.question_item"
                                                            :key="opt_index"
                                                            :class="{ is_answer: opt.teque_ite_isanswer == 10 }">
                                                            <p>
                                                                <span class="flex">
                                                                    {{ opt.teque_ite_code }}. <span class="richinline" v-html="opt.teque_ite_title"></span>
                                                                </span>
                                                                <el-image fit="scale-down" v-if="opt.teque_ite_image"
                                                                          :src="formatfile(opt.teque_ite_image)"
                                                                          :preview-src-list="[formatfile(opt.teque_ite_image)]"/>
                                                            </p>
                                                        </li>
                                                    </ol>
                                                    <p class="question-answer"
                                                       v-if="!isChoice(que.syque_typ_id) && !$isEmpty(sque.sure_answer)">
                                                       <span class="richinline" v-html="sque.sure_answer.join(',')"></span>
                                                    </p>
                                                </div>
                                            </template>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-group">
                <el-button type="custom_info" size="small" @click="$router.go(-1)">取消</el-button>
                <el-button type="custom_primary" size="small" @click="addPaper" :disabled="btnload" v-loading="btnload">
                    提交
                </el-button>
            </div>
        </div>
        <!-- 选题弹窗 -->
        <el-dialog :visible.sync="questionDialog" :width="groupQuesWay == 1 && '1000px' || '410px'" title="添加题"
                   :show-close="false" :close-on-click-modal="false">
            <div class="question-choose" :class="{ voluntarily: groupQuesWay == 2 }">
                <h5 class="bold type-tip" v-if="!$isEmpty(paperForm.option_arr) && paperForm.option_arr[currentQgi]">
                    {{ paperForm.option_arr[currentQgi].tepap_top_title }}
                </h5>
                <!-- 组题类型 -->
                <div class="choose-type" v-if="questionTi != 14">
                    <el-radio-group v-model="groupQuesWay">
                        <el-radio :label="1">手动组题</el-radio>
                        <el-radio :label="2">随机组题</el-radio>
                    </el-radio-group>
                </div>
                <!-- 手动组题 -->
                <div class="choose-manual" v-if="groupQuesWay == 1">
                    <div class="choose-manual--search">
                        <el-input v-model.trim="searchKey" maxlength="100" placeholder="请输入关键字查询标题" clearable
                                  @clear="searchQues">
                            <span slot="prefix" class="iconfont">&#xe61c;</span>
                        </el-input>
                        <el-button type="custom_primary" size="medium" @click="searchQues">查询</el-button>
                    </div>
                    <div class="choose-manual--question">
                        <el-checkbox-group v-model="manualChoose" @input="chooseQues">
                            <!-- 滚动加载更多 -->
                            <ul class="question-list" v-infinite-scroll="loadQues" infinite-scroll-disabled="disabled"
                                infinite-scroll-distance="1">
                                <li class="question-item" :class="{ combination: questionTi == 14 }"
                                    v-for="(que, que_index) in manualQues" :key="que.teque_id">
                                    <p class="question--no">
                                        <span class="question--num">{{ (que_index + 1) > 9 && que_index + 1 ||
                                            `0${que_index + 1}` }}.</span>
                                    </p>
                                    <div class="question--data">
                                        <div class="question--title">
                                            <h5 class="bold richinline" v-html="que.teque_title"></h5>
                                            <p class="img-box" v-if="que.teque_title_image">
                                                <el-image fit="scale-down" :src="formatfile(que.teque_title_image)"
                                                          :preview-src-list="[formatfile(que.teque_title_image)]"/>
                                            </p>
                                            <div class="custom-input" v-if="questionTi == 14">
                                                <el-input v-model.trim="que.teque_score" maxlength="4" placeholder="0"
                                                          :readonly="questionTi == 14"/>
                                                <span>分</span>
                                            </div>
                                            <div class="custom-input" v-else>
                                                <el-input v-model.trim="que.teque_score" maxlength="4" placeholder="输入"
                                                          @input="que.teque_score = iptInit(que.teque_score, 2, 150)"
                                                          @blur="que.teque_score = iptInitBlur(que.teque_score, 0); chooseQues()"/>
                                                <span>分</span>
                                            </div>
                                            <el-checkbox :label="que.teque_id">加入试卷</el-checkbox>
                                        </div>
                                        <!-- 组合题 -->
                                        <div class="combination--data" v-if="questionTi == 14">
                                            <ol class="combination--subtopic">
                                                <li class="subtopic-item"
                                                    v-for="(sque, sque_index) in que.children_question"
                                                    :key="sque_index">
                                                    <p class="subtopic--no">
                                                        <span class="subtopic--num">({{ sque_index + 1 }})</span>
                                                        <span class="subtopic--type">{{ sque.syque_typ_name }}</span>
                                                    </p>
                                                    <div class="subtopic--data">
                                                        <div class="subtopic--title">
                                                            <el-image style="width: 46px;height: 32px;margin:0 10px;" v-if="sque.teque_title_image" fit="cover" :src="formatfile(sque.teque_title_image)" :preview-src-list="[formatfile(sque.teque_title_image),]" />
                                                            <!-- 小题标题 -->
                                                            <p> <span class="richinline" v-html="sque.teque_title "></span></p>
                                                            <!-- 分数输入控件 -->
                                                            <div class="custom-input">
                                                                <el-input v-model.trim="sque.teque_score" maxlength="4"
                                                                          placeholder="输入"
                                                                          @input="sque.teque_score = iptInit(sque.teque_score, 2, 150)"
                                                                          @blur="sque.teque_score = iptInitBlur(sque.teque_score, 0); scoreChange(que_index)"/>
                                                                <span>分</span>
                                                            </div>
                                                        </div>
                                                        <!-- 小题(选择题)选项 -->
                                                        <dl class="subtopic--options"
                                                            v-if="isChoice(sque.syque_typ_id)">
                                                            <dt class="option"
                                                                v-for="(opt, opt_index) in sque.question_item"
                                                                :key="opt_index">
                                                                <span class="flex">
                                                                    {{ opt.teque_ite_code }}. <span class="richinline" v-html="opt.teque_ite_title"></span>
                                                                </span>
                                                                <el-image v-if="opt.teque_ite_image" fit="cover"
                                                                          :src="formatfile(opt.teque_ite_image)"
                                                                          :preview-src-list="[formatfile(opt.teque_ite_image)]"/>
                                                            </dt>
                                                        </dl>
                                                        <!-- 小题答案 -->
                                                        <p class="question--answer" v-if="!$isEmpty(sque.sure_answer)">
                                                            答案：<span class="richinline" v-html="sque.sure_answer.join(',')"></span>
                                                        </p>
                                                    </div>
                                                </li>
                                            </ol>
                                        </div>
                                        <!-- 非组合题 -->
                                        <div v-else v-for="(sque, sque_index) in que.children_question"
                                             :key="sque_index">
                                            <ul class="question--options" v-if="isChoice(questionTi)">
                                                <li class="option" v-for="(opt, opt_index) in sque.question_item"
                                                    :key="opt_index">
                                                    <span class="flex">
                                                        {{ opt.teque_ite_code }}. <span class="richinline" v-html="opt.teque_ite_title"></span>
                                                    </span>
                                                    <el-image v-if="opt.teque_ite_image" fit="cover"
                                                              :src="formatfile(opt.teque_ite_image)"
                                                              :preview-src-list="[formatfile(opt.teque_ite_image)]"/>
                                                </li>
                                            </ul>
                                            <p class="question--answer" v-if="!$isEmpty(sque.sure_answer)">
                                                答案：<span class="richinline" v-html="sque.sure_answer.join(',')"></span>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </el-checkbox-group>
                        <!-- 加载控件 -->
                        <div class="custom-loading" v-if="manualQuesLoading">
                            <div class="point-item"></div>
                            <div class="point-item"></div>
                            <div class="point-item"></div>
                        </div>
                        <p class="custom-no-more" v-if="noMore">没有更多了~</p>
                    </div>
                </div>
                <!-- 自动组题 -->
                <div class="choose-voluntarily" v-if="groupQuesWay == 2">
                    <el-form ref="voluntarilyForm" :model="voluntarilyForm" label-position="top"
                             :rules="voluntarilyRules">
                        <el-form-item label="设置题型数量" prop="syque_typ_num">
                            <el-input v-model.trim="voluntarilyForm.syque_typ_num" maxlength="100"
                                      @input="voluntarilyForm.syque_typ_num = iptInit(voluntarilyForm.syque_typ_num, 1)"/>
                        </el-form-item>
                        <el-form-item label="设置每道题目分数" prop="syque_typ_score">
                            <el-input v-model.trim="voluntarilyForm.syque_typ_score" maxlength="4"
                                      @input="voluntarilyForm.syque_typ_score = iptInit(voluntarilyForm.syque_typ_score, 2, 150 / voluntarilyForm.syque_typ_num);"
                                      @blur="voluntarilyForm.syque_typ_score = iptInitBlur(voluntarilyForm.syque_typ_score, '')"/>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="footer" class="paper-foot" :class="{ wrap: groupQuesWay != 1 }">
                <p class="statistics" v-if="groupQuesWay == 1">
                    以上选择题
                    <span class="pf_bold">{{ manualChoose.length }}</span>
                    道题，共计
                    <span class="pf_bold score">{{ finalScoring }}</span>分
                </p>
                <p class="statistics" v-if="groupQuesWay == 2">
                    以上选择题
                    <span class="pf_bold">{{ voluntarilyForm.syque_typ_num }}</span>
                    道题，共计
                    <span class="pf_bold score">
                        {{ $_.round(voluntarilyForm.syque_typ_num * voluntarilyForm.syque_typ_score, 1) || 0 }}
                    </span>分
                </p>
                <div>
                    <el-button type="custom_info" size="small" @click="hideDialog">
                        取 消
                    </el-button>
                    <el-button type="custom_primary" size="small" @click="appendQuestion">
                        确 定
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {$addExampaper, $manualTopic, $editExampaper, $randomTopic, $exampaperDetail} from "@api/homeworkExam"
    import {mapState} from "vuex";
    import {formatFileType,formatFile, iptInteger, iptFloat} from "@utils";

    export default {
        name: 'he_examPaperModify',
        computed: {
            ...mapState("common", ["gradeList", "subjectList", 'questionType']),
            ...mapState("user", ["userInfo"]),
            formatfile() {
                return function (url) {
                    return formatFile(url)
                }
            },
            fileType() {
      return function (val) {
        return formatFileType(val);
      };
    },
            iptInit() {
                return function (val, type, max) {
                    if (max && Number(val) > max) val = max
                    if (type == 1) val = iptInteger(val + '', '')
                    if (type == 2) val = iptFloat(val + '');
                    console.log(val)
                    return val
                }
            },
            iptInitBlur() {
                return function (val, noval) {
                    if (!val) return noval;
                    if (val && val.split('.')[1] == '') return val.split('.')[0];
                    return val;
                }
            },
            /** 判断是否是选择题 */
            isChoice() {
                const choiceTypeIds = [1, 2, 5];
                return function (val) {
                    return choiceTypeIds.indexOf(val) != -1;
                };
            },
            noMore() {
                return this.manualQues.length >= this.manualQuesPage.count;
            },
            disabled() {
                return this.manualQuesLoading || this.noMore
            },
            /** 选中题目总分计算 */
            finalScoring() {
                let chooseQues = [...this.manualChooseQues];
                let totalScore = 0;
                chooseQues.map(que => {
                    totalScore += Number(que.teque_score) || 0
                });
                return this.$_.round(totalScore, 1);
            },
            /** 题组总分计算 */
            qgScoring() {
                return function (data) {
                    let totalScore = 0;
                    if (!this.$isEmpty(data)) {
                        data.map(que => {
                            totalScore += Number(que.teque_score) || 0
                        })
                    }
                    return this.$_.round(totalScore, 1);
                }
            },
            /** 试卷总分计算 */
            paperScoring() {
                let {option_arr} = this.paperForm;
                let totalScore = 0;
                option_arr.map(qg => {
                    if (!this.$isEmpty(qg.option_item)) {
                        qg.option_item.map(que => {
                            totalScore += Number(que.teque_score) || 0
                        })
                    }
                });
                return this.$_.round(totalScore, 1);
            }
        },
        data() {
            let validateScore = (rule, value, callback) => {
                this.voluntarilyForm = {
                    ...this.voluntarilyForm, syque_typ_score: value
                }
                if (this.groupQuesWay == 2 && !value) {
                    callback(new Error("请设置每道题目分数"));
                } else if (this.groupQuesWay == 2 && value == 0) {
                    callback(new Error("每道题目分数应大于0"));
                }
                else {
                    callback();
                }
            };
            return {
                paperId: null, // 试卷id
                paperForm: {
                    tepap_semester: 1,
                    tepap_make_type: 10,
                    tepap_isshare_status: 10,
                    option_arr: [], // 题组
                }, // 试卷表单
                paperRules: {
                    tepap_grade: [{required: true, message: "请选择年级", trigger: "change"}],
                    sysub_id: [{required: true, message: "请选择考试科目", trigger: "change"}],
                    tepap_title: [{required: true, message: "请填写试卷名称", trigger: "blur"}],
                    tepap_rule: [{required: true, message: "请填写考试规则", trigger: "blur"}],
                },
                questionGroup: {},
                questionRules: {
                    tepap_top_title: [{required: true, message: "请填写题组标题", trigger: "blur"},],
                    syque_typ_id: [{required: true, message: "请选择题组题型", trigger: "change"},],
                },
                questionDialog: false, // 显示选题弹窗
                currentQgi: null, // 题组下标
                questionTi: null, // 题型id
                groupQuesWay: 1, // 组题方式1. 手动   2. 随机
                attachmePaperList: [], // 试卷资源
      attachmeanalysisList: [], // 解析资源
                /* 手动选题题组 */
                manualQues: [], // 手动选题题组
                searchKey: '', // 筛选关键字
                manualQuesLoading: false, // 分页数据加载
                manualQuesPage: {count: 0, pageIndex: 1}, // 分页参数
                manualChoose: [], // 选中题目组(题目id)
                manualChooseQues: [], // 选中题目组(题目)
                voluntarilyForm: {}, // 随机组题表单
                voluntarilyRules: {
                    syque_typ_num: [{required: true, message: "请设置题型数量", trigger: "blur"},],
                    syque_typ_score: [{required: true, validator: validateScore, trigger: "blur"},],
                },
                btnload: false,
            }
        },
        created() {
            let {id} = this.$route.params;
            if (id) {
                this.paperId = id;
                this.getPaperDetail();
            }
        },
        methods: {
            /** 添加题组 */
            addQuesGroup() {
                this.$refs.questionGroup.validate(valid => {
                    if (valid) {
                        let questionGroup = {...this.questionGroup, show: true}
                        let option_arr = this.paperForm.option_arr;
                        option_arr = [...option_arr, {...questionGroup}];
                        this.questionGroup = {};
                        this.$refs.questionGroup.resetFields();
                        this.paperForm = {
                            ...this.paperForm,
                            option_arr: [...option_arr],
                        }
                        this.$forceUpdate();
                    }
                })
            },
            /**
             * 移除题组
             * @param {number} qg_index 题组下标
             */
            delQg(qg_index) {
                let {option_arr} = this.paperForm;
                option_arr.splice(qg_index, 1)
                this.paperForm = {...this.paperForm, option_arr: [...option_arr]};
                this.$forceUpdate();
            },
            /**
             * 移除题目
             * @param {number} qg_index 题组下标
             * @param {number} que_index 题目下标
             */
            delQues(qg_index, que_index) {
                let {option_arr} = this.paperForm;
                let {option_item} = option_arr[qg_index]
                option_item.splice(que_index, 1);
                option_arr[qg_index].option_item = [...option_item];
                this.paperForm = {...this.paperForm, option_arr: [...option_arr]};
                this.$forceUpdate();
            },
            /** 获取可选题目列表 */
            async getQuesList() {
                let params = {
                    teque_title: this.searchKey,
                    syque_typ_id: this.questionTi,
                    pageindex: this.manualQuesPage.pageIndex,
                    grade: this.paperForm.tepap_grade,
                    semester: this.paperForm.tepap_semester,
                    sysub_id: this.paperForm.sysub_id
                }
                let chooseQues = [...this.manualChooseQues]; // 选题题组
                let {data: res} = await $manualTopic(params);
                this.manualQuesLoading = false; // 数据加载隐藏
                this.manualQuesPage.count = res.allcount; // 数据总数
                /* 分数回显 */
                res.data.map(que => {
                    if (!this.$isEmpty(chooseQues)) {
                        chooseQues.map(cque => {
                            if (que.teque_id == cque.teque_id) {
                                que.teque_score = cque.teque_score
                            }
                            /* 组合题小题分数回显 */
                            if (this.questionTi == 14) {
                                que.children_question.map(sque => {
                                    cque.children_question.map(scque => {
                                        if (sque.teque_id == scque.teque_id) {
                                            sque.teque_score = scque.teque_score
                                        }
                                    })
                                })
                            }
                        })
                    }
                })
                this.manualQues = [...this.manualQues, ...res.data]; // 题目列表数据赋值
                this.$forceUpdate();
            },
            /** 加载更多可选题目列表 */
            loadQues() {
                this.manualQuesPage.pageIndex = this.manualQuesPage.pageIndex + 1
                this.manualQuesLoading = true;
                setTimeout(() => {
                    this.getQuesList();
                }, 300);
            },
            /** 搜索题目列表 */
            searchQues() {
                this.manualQuesPage.pageIndex = 0;
                this.manualQues = [];
                this.manualQuesLoading = true;
                setTimeout(() => {
                    this.getQuesList();
                }, 300);
            },
            /** 手动选题 - 选中题目 */
            chooseQues() {
                let ques = []; // 选中的题目数据
                this.manualQues.map(que => {
                    this.manualChoose.map(id => {
                        if (que.teque_id == id) ques.push(que)
                    })
                });
                this.manualChooseQues = [...ques];
            },
            /**
             * 分数改变（组合题）
             * @param {number} 题目下标
             */
            scoreChange(que_index) {
                let que = this.manualQues[que_index];
                let queScore = 0;
                que.children_question.map(sque => {
                    queScore += Number(sque.teque_score) || 0
                })
                que.teque_score = queScore;
                this.manualQues[que_index] = {...que};
                this.chooseQues();
                this.$forceUpdate();
            },
            /**
     * 文件上传成功
     * @param {object} data 响应结果
     * @param {string} name 上传原文件名
     */
    uploadPaperSuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.paperForm.tepap_att_paper_url = data.address;
      let courseware = { name, type: data.file_type };
      this.attachmePaperList = [courseware];
      this.$refs.paperForm.validateField("tepap_att_paper_url");
    },
    /**
     * 文件上传成功
     * @param {object} data 响应结果
     * @param {string} name 上传原文件名
     */
    uploadSuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.paperForm.tepap_att_analysis_url = data.address;
      let courseware = { name, type: data.file_type };
      this.attachmeanalysisList = [courseware];
      this.$refs.paperForm.validateField("tepap_att_analysis_url");
    },
            /** 添加选中题目/随机组题题目 到题组 */
            appendQuestion() {
                let paperScore = 0;
                let {option_arr: qgs} = this.paperForm;
                qgs = qgs.filter(item => item.syque_typ_id != this.questionTi)
                qgs.map(qg => {
                    if (!this.$isEmpty(qg.option_item)) {
                        qg.option_item.map(que => {
                            paperScore += Number(que.teque_score) || 0
                        })
                    }
                });
                paperScore = this.$_.round(paperScore, 1);
                if (this.groupQuesWay == 1) {
                    let chooseQues = [...this.manualChooseQues]; // 选中的题目数据
                    let notScore = false; // 选中题目是否有没有分数值的项
                    /* 组合题 */
                    if (this.questionTi == 14) {
                        chooseQues.map(que => {
                            if (!que.teque_score || que.teque_score == 0) {
                                notScore = true;
                            }
                            else {
                                que.children_question.map(sque => {
                                    if (!sque.teque_score || sque.teque_score == 0) {
                                        notScore = true;
                                    }
                                })
                            }
                        })
                    }
                    /* 非组合题 */
                    else {
                        chooseQues.map(que => {
                            if (!que.teque_score || que.teque_score == 0) {
                                notScore = true;
                            }
                        })
                    }
                    /* 不存在无分数值的项 */
                    if (!notScore) {
                        let totalScore = this.finalScoring;
                        if (totalScore + paperScore > 150) return this.$message.error('试卷总分超出150,请合理设置题目分数');
                        let {option_arr} = this.paperForm;
                        option_arr[this.currentQgi] = {
                            ...option_arr[this.currentQgi],
                            option_item: [...chooseQues],
                        }
                        this.paperForm = {
                            ...this.paperForm,
                            option_arr: [...option_arr]
                        };
                        this.$forceUpdate();
                        this.hideDialog();
                    } else return this.$message.warning('请设置选中题目小题分数')
                }
                if (this.groupQuesWay == 2) {
                    this.$refs.voluntarilyForm.validate(async (valid) => {
                        if (valid) {
                            let {syque_typ_num, syque_typ_score} = this.voluntarilyForm;
                            let totalScore = this.$_.round(syque_typ_num * syque_typ_score, 1);
                            if (totalScore + paperScore > 150) return this.$message.error('试卷总分超出150,请合理设置题目分数');
                            let params = {
                                syque_typ_num, syque_typ_score,
                                syque_typ_id: this.questionTi,
                                grade: this.paperForm.tepap_grade,
                                semester: this.paperForm.tepap_semester,
                                sysub_id: this.paperForm.sysub_id
                            }
                            let {data} = await $randomTopic(params);
                            let {option_arr} = this.paperForm;
                            option_arr[this.currentQgi] = {
                                ...option_arr[this.currentQgi],
                                option_item: [...data],
                            }
                            this.paperForm = {
                                ...this.paperForm,
                                option_arr: [...option_arr]
                            };
                            this.hideDialog();
                            this.$forceUpdate();
                        }
                    })
                }
            },
            /**
             * 打开选题弹窗
             * @param {number} id 题型id
             * @param {number} index 题组下标
             */
            showDialog(id, index) {
                this.questionTi = id; // 题型id
                this.currentQgi = index; // 题组下标
                let {option_arr} = this.paperForm; // 试卷题组
                let currentQg = option_arr[index]; // 选题题组
                let manualChoose = []; // 选中题目id
                let manualChooseQues = []; // 选中题目组
                /* 选中题目id赋值 */
                if (!this.$isEmpty(currentQg.option_item)) {
                    currentQg.option_item.map(que => {
                        manualChoose.push(que.teque_id)
                        manualChooseQues.push(que)
                    })
                }
                this.manualChoose = [...manualChoose]; // 选中题目id
                this.manualChooseQues = [...manualChooseQues]; // 选中题目组
                if (this.groupQuesWay == 1) this.getQuesList(); // 获取题目列表
                this.questionDialog = true; // 选题弹窗显示
            },
            /** 关闭弹窗 */
            hideDialog() {
                /* 重置选题相关数据 */
                this.groupQuesWay = 1, // 组题方式
                    this.searchKey = '', // 题目筛选关键字
                    this.questionTi = null; // 题型id
                this.currentQgi = null; // 题组下标
                this.manualQues = []; // 手动选题题目列表
                this.manualQuesPage = {count: 0, pageIndex: 1}; // 手动选题列表分页
                this.manualChoose = []; // 题目选中id组
                this.manualChooseQues = []; // 题目选中题组
                this.voluntarilyForm = {}; // 随机组题表单
                this.questionDialog = false; // 关闭选题弹窗
            },
             /** 
         * 移出上传课件资源
         */
        removeCoursewareFile(type) {
            if(type==1){
                delete this.paperForm.tepap_att_paper_url;
                this.attachmePaperList = [];
            }else if(type==2){
                delete this.paperForm.tepap_att_analysis_url;
                this.attachmeanalysisList = [];
            }
            console.log(this.paperForm,'dddd');
        },
            /** 创建试卷 */
            addPaper() {
                this.$refs.paperForm.validate(async (valid) => {
                    if (valid) {
                        if (this.paperScoring > 150) return this.$message.error('试卷总分超出150,请合理设置题目分数');
                        let paperForm = this.paperForm;
                        let {option_arr} = paperForm;
                        let notQue = false;
                        if (paperForm.tepap_make_type == 10) {
            if (this.$isEmpty(option_arr)) {
              return this.$message.error("请填充试卷题目");
            } else {
              option_arr.map((qg) => {
                if (this.$isEmpty(qg.option_item)) notQue = true;
              });
            }
            if (notQue) return this.$message.warning("请完整填充题型题目");
          } else {
            if (
              this.$isEmpty(paperForm.tepap_att_paper_url) ||
              this.$isEmpty(paperForm.tepap_att_analysis_url)
            ) {
              return this.$message.error("请完善试卷和试卷解析");
            }
          }
                        this.btnload = true;
                        let qgArr = [];
                        let smallscore = [];
                        option_arr.map(qg => {
                            let obj = {
                                tepap_top_title: qg.tepap_top_title,
                                syque_typ_id: qg.syque_typ_id,
                            }
                            let ques = [];
                            qg.option_item.map(que => {
                                let que_obj = {
                                    teque_id: que.teque_id,
                                    teque_score: que.teque_score
                                }
                                ques.push(que_obj)
                                if (qg.syque_typ_id == 14) {
                                    que.children_question.map(sque => {
                                        let sque_obj = {
                                            teque_id: sque.teque_id,
                                            score: sque.teque_score
                                        }
                                        smallscore.push(sque_obj)
                                    })
                                }
                            });
                            obj.option_item = [...ques];
                            qgArr.push(obj)
                        })
                        let paramsKey = ['tepap_grade', "sysub_id", 'tepap_semester', 'tepap_title', 'tepap_rule', 'tepap_isshare_status',   "tepap_make_type",
            "tepap_att_paper_url",
            "tepap_att_analysis_url"];
                        let params = {
                            option_arr: [...qgArr],
                            smallscore: [...smallscore],
                        }
                        paramsKey.map(key => {
                            params[key] = paperForm[key]
                        })
                        if (this.userInfo.enter != 1) params.tepap_isshare_status = 10;
                        if (this.paperId) return this.editPaper(params);
                        let res = await $addExampaper(params);
                        this.btnload = false;
                        if (res) {
                            this.$message.success('试卷新增成功');
                            this.$router.go(-1);
                        }
                    }
                })
            },
            /** 编辑试卷 */
            async editPaper(params) {
                params = {...params, tepap_id: this.paperId};
                let res = await $editExampaper(params);
                this.btnload = false;
                if (res) {
                    this.$message.success('试卷编辑成功');
                    this.$router.go(-1);
                }
            },
            /** 试卷详情 */
            async getPaperDetail() {
                let {data} = await $exampaperDetail(1, this.paperId);
                let option_arr = [...data.paper_question];
                let formKeys = ['tepap_grade', 'tepap_semester', 'sysub_id', 'tepap_title', 'tepap_rule', 'tepap_isshare_status','tepap_make_type','tepap_att_analysis_url','tepap_att_paper_url'];
                let formData = {}
                formKeys.map(key => {
                    formData[key] = data[key];
                });
                option_arr.map(qg => {
                    let option_item = [];
                    if (qg.syque_typ_id == 14) {
                        qg.question_info.map(que => {
                            que.children_question = [...que.question_arr];
                            delete que.question_arr;
                            option_item.push(que);
                        })
                    } else {
                        qg.question_info.map(que => {
                            que.children_question = [{
                                question_item: [...que.question_item],
                                sure_answer: [...que.sure_answer]
                            }];
                            delete que.question_item;
                            delete que.sure_answer
                            option_item.push(que);
                        })
                    }
                    delete qg.question_info;
                    qg.option_item = [...option_item];
                    qg.show = true;
                });
                formData.option_arr = [...option_arr];
                this.paperForm = {...formData};
                if(this.paperForm.tepap_make_type==20){
                    let courseware1 = { name:this.paperForm.tepap_att_paper_url,type:'pdf' };
                this.attachmePaperList = [courseware1];
                let courseware = { name:this.paperForm.tepap_att_analysis_url,type:'pdf' };
                this.attachmeanalysisList = [courseware];
                }
               
                this.$forceUpdate();
            },
        }
    }
</script>